import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Product from 'components/Product';

import { useScreenService } from 'hooks/useScreenService';

import './WhichProductPage.scss';

const CONSTANTS = {
  BACK: 'Back',
};

const WhichProductPage: FC<WhichProductTypes.WhichProductsPageProps> = ({
  data: {
    whichProducts: { seo, urls, heading, subheading, questions },
    allProductDetail,
  },
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentSecondQuestion, setCurrentSecondQuestion] = useState(null);
  const [fourthProductData, setFourthProductData] = useState(null);
  const [step, setStep] = useState(0);

  const { isLgUp } = useScreenService();

  const { title: seoTitle, keywords, description } = seo;

  const handleFocus = (question, questionStep) => {
    const element = document?.querySelector(
      `[data-id="${questionStep}-${question}"]`
    ) as HTMLElement | null;
    const btn = document.querySelector('.product__product-button') as HTMLElement | null;

    if (element) {
      element?.focus();
    } else if (btn) {
      btn.focus();
    } else if (!btn && !element) {
      const prevEl = document?.querySelector(
        `[data-id="${questionStep}-${question - 1}"]`
      ) as HTMLElement | null;

      if (prevEl) prevEl.focus();
    }
  };

  const handleQuestion = useCallback((question, questionStep) => {
    switch (questionStep) {
      case 1: {
        setStep(questionStep);
        setCurrentQuestion(question);
        setCurrentSecondQuestion(null);
        setFourthProductData(null);

        return true;
      }
      case 2: {
        setStep(questionStep);
        setCurrentSecondQuestion(question);
        setFourthProductData(null);

        return true;
      }
      case 3: {
        setStep(questionStep);
        setFourthProductData(question);

        return true;
      }
      default:
        return false;
    }
  }, []);

  const handleBack = useCallback(() => {
    if (!isLgUp) {
      if (step === 1) {
        setCurrentQuestion(null);
      } else if (step === 2) {
        setCurrentSecondQuestion(null);
      } else if (step === 3) {
        setFourthProductData(null);
      }

      setStep((prevStep) => prevStep - 1);
    }
  }, [isLgUp, step]);

  const secondElements = useMemo(() => {
    const handleKeyUp = (e, question, questionStep) => {
      if (e.keyCode === 13) {
        if (questionStep === 1) {
          setTimeout(handleFocus, 100, question, questionStep + 1);
        } else if (questionStep === 2) {
          setTimeout(handleFocus, 100, question, questionStep + 1);
        } else if (questionStep === 3) {
          setTimeout(handleFocus, 100, question, questionStep + 1);
        }
      }
    };

    return questions.map(({ title, answers }) => (
      <div key={title} className="which-product-page__slider-wrapper">
        <div className="which-product-page__slider">
          {!isLgUp && Number.isInteger(currentQuestion) ? null : (
            <div className="which-product-page__wrapper">
              <h2 className="which-product-page__title">{title}</h2>
              {answers.map(({ questionOne }, idx) => (
                <button
                  key={questionOne}
                  type="button"
                  className={classNames('which-product-page__question-button', {
                    'which-product-page__question-button--active':
                      isLgUp && currentQuestion === idx,
                  })}
                  onClick={() => handleQuestion(idx, 1)}
                  data-id={`1-${idx}`}
                  onKeyUp={(e) => handleKeyUp(e, idx, 1)}
                >
                  {questionOne}
                </button>
              ))}
            </div>
          )}
          {Number.isInteger(currentQuestion) &&
          (isLgUp || !Number.isInteger(currentSecondQuestion)) ? (
            <div className="which-product-page__wrapper">
              {(() => {
                const answersFind = answers.find((_, idx) => idx === currentQuestion);
                const quesitonTwoHeading = answersFind?.secondQuestion[0].questionTwo;

                return (
                  <>
                    {quesitonTwoHeading ? (
                      <h2 className="which-product-page__title">{quesitonTwoHeading}</h2>
                    ) : null}
                    {answersFind?.secondQuestion.map(
                      ({ answerPicker, recommendedSolution }, index) => {
                        const retProduct =
                          recommendedSolution.length > 0
                            ? allProductDetail.nodes.find(
                                ({ pageName }) =>
                                  pageName === recommendedSolution[0].product[0].name
                              )
                            : null;

                        const retElement = retProduct ? (
                          <Product
                            heading={recommendedSolution[0].recommendationHeading}
                            imageStructure={retProduct?.packshot.imageStructure}
                            pageName={retProduct.pageName}
                            url={retProduct.url}
                            key={answerPicker}
                            extraDescription={recommendedSolution[0]?.extraDescription}
                          />
                        ) : null;

                        return answerPicker ? (
                          <button
                            key={answerPicker}
                            type="button"
                            className={classNames('which-product-page__question-button', {
                              'which-product-page__question-button--active':
                                isLgUp && currentSecondQuestion === index,
                            })}
                            onClick={() => handleQuestion(index, 2)}
                            data-id={`2-${index}`}
                            onKeyUp={(e) => handleKeyUp(e, index, 2)}
                          >
                            {answerPicker}
                          </button>
                        ) : (
                          retElement
                        );
                      }
                    )}
                  </>
                );
              })()}
            </div>
          ) : null}
          {Number.isInteger(currentSecondQuestion) &&
          (isLgUp || !Number.isInteger(fourthProductData)) ? (
            <div className="which-product-page__wrapper">
              {(() => {
                const content = answers
                  ?.find((_, idxx) => idxx === currentQuestion)
                  ?.secondQuestion.find((_, idx) => idx === currentSecondQuestion);

                const threeQuestionsVariable =
                  content?.questionThree && content?.questionThree.length > 0 ? (
                    <>
                      <h2 className="which-product-page__title">
                        {content?.questionThree[0]?.question}
                      </h2>
                      {content?.questionThree.map(({ answer }, index) => (
                        <button
                          key={answer}
                          type="button"
                          className={classNames('which-product-page__question-button', {
                            'which-product-page__question-button--active':
                              isLgUp && fourthProductData === index,
                          })}
                          onClick={() => handleQuestion(index, 3)}
                          data-id={`3-${index}`}
                          onKeyUp={(e) => handleKeyUp(e, index, 3)}
                        >
                          {answer}
                        </button>
                      ))}
                    </>
                  ) : null;

                const retProduct =
                  content?.recommendedSolution && content?.recommendedSolution.length > 0
                    ? allProductDetail.nodes.find(
                        ({ pageName }) =>
                          pageName === content?.recommendedSolution[0].product[0].name
                      )
                    : null;

                const retElement = retProduct ? (
                  <Product
                    heading={content?.recommendedSolution[0].recommendationHeading}
                    imageStructure={retProduct?.packshot.imageStructure}
                    pageName={retProduct.pageName}
                    url={retProduct.url}
                    extraDescription={content?.recommendedSolution[0]?.extraDescription}
                  />
                ) : null;

                return content?.recommendedSolution && content?.recommendedSolution?.length > 0
                  ? retElement
                  : threeQuestionsVariable;
              })()}
            </div>
          ) : null}
          {Number.isInteger(fourthProductData) ? (
            <div className="which-product-page__wrapper">
              {(() => {
                const content = answers
                  ?.find((_, idxx) => idxx === currentQuestion)
                  ?.secondQuestion.find((_, idx) => idx === currentSecondQuestion);

                const fourthAnswer =
                  content?.questionThree && content?.questionThree?.length > 0
                    ? content.questionThree.find((_, idx) => fourthProductData === idx)
                    : null;

                const retProduct =
                  fourthAnswer && fourthAnswer.product.length > 0
                    ? allProductDetail.nodes.find(
                        ({ pageName }) => pageName === fourthAnswer.product[0].name
                      )
                    : null;

                const retElement = retProduct ? (
                  <Product
                    imageStructure={retProduct.packshot.imageStructure}
                    pageName={retProduct.pageName}
                    url={retProduct.url}
                    extraDescription={fourthAnswer?.extraDescription}
                  />
                ) : null;

                return retElement;
              })()}
            </div>
          ) : null}
        </div>
        {!isLgUp && step > 0 ? (
          <button type="button" onClick={handleBack} className="which-product-page__back-button">
            {CONSTANTS.BACK}
          </button>
        ) : null}
      </div>
    ));
  }, [
    allProductDetail.nodes,
    currentQuestion,
    currentSecondQuestion,
    fourthProductData,
    handleBack,
    handleQuestion,
    isLgUp,
    questions,
    step,
  ]);

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <div className="which-product-page__width-wrapper">
        <div className="which-product-page__heading-wrapper">
          <h1 className="which-product-page__heading">{heading}</h1>
        </div>
        <h2 className="which-product-page__subheading">{subheading}</h2>
        {secondElements}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    allProductDetail {
      nodes {
        url
        packshot {
          ...TransparentImageStructureFragment
        }
        pageName
      }
    }
    whichProducts(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      questions {
        answers {
          questionOne
          secondQuestion {
            answerPicker
            questionTwo
            recommendedSolution {
              button {
                ariaLabel
              }
              product {
                name
                udi
                url
                icon
                published
                trashed
              }
              recommendationHeading
              extraDescription
            }
            questionThree {
              answer
              question
              extraDescription
              product {
                icon
                name
                trashed
                udi
                url
                published
              }
            }
          }
        }
        title
      }
      heading
      subheading
    }
  }
`;

export default WhichProductPage;
