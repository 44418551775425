import React, { FC, useMemo } from 'react';
import { navigate } from 'gatsby';

import Image from 'common/Image';
import Typography from 'common/Typography';

import { ProductProps } from './models';

import './Product.scss';

const CONSTANTS = {
  PRODUCT_DETAIL: 'Product detail',
};

const Product: FC<ProductProps> = ({
  heading,
  imageStructure,
  pageName,
  url,
  extraDescription,
}) => {
  const retProduct = useMemo(
    () => (
      <div className="product">
        {heading ? <p className="product__product-reccomend">{heading}</p> : null}
        <div className="product__product-image-wrapper">
          <Image
            className="product__product-image"
            alt={imageStructure.alt}
            imageData={imageStructure.imageData}
            key={imageStructure.alt}
          />
        </div>
        <p className="product__product-name">{pageName}</p>
        {extraDescription ? (
          <Typography
            dangerouslySetInnerHTML={extraDescription}
            className="product__product-desc"
          />
        ) : null}
        <button type="button" onClick={() => navigate(url)} className="product__product-button">
          {CONSTANTS.PRODUCT_DETAIL}
        </button>
      </div>
    ),
    [extraDescription, heading, imageStructure.alt, imageStructure.imageData, pageName, url]
  );

  return retProduct;
};

export default Product;
